<template>
  <div class="documentation">
    <h1>Launchpad Documentation</h1>

    <p>Launchpad is under extremely active development and nailing down documentation
      that stays relevant has been challenging. We are working on changing this, and
      we could use your help if you are a Launchpad user.</p>

    <h2>Launchpad Wiki</h2>

    <p>The place where the most current documentation exists right now is in our
      <a href="https://github.com/VolantisDev/Launchpad/wiki">wiki on GitHub</a>.</p>

    <p>The documentation here is sparse for the time being, but as time permits and as
      things stabilize in Launchpad we will be greatly expanding the documentation in
      the wiki.</p>
  </div>
</template>
